<template>
  <div>
    <headerView />
    <bannerView ref="banner" :program="program" />
    <div v-if="showNewHeaderMobile" class="newHeaderMobile lg:hidden">
      <div class="max-w-7xl m-auto">
        <div class="flex items-center overflow-x-auto justify-around">
          <div class="flex gap-x-4">
            <div
              v-for="el in programData"
              :key="el.id"
              class="font-medium cursor-pointer pb-4 text-xs nav-item"
              @click="
                navBarActive(el.name);
                selectedId = el.id;
              "
              :tabId="el.name"
            >
              {{ el.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showHeader" class="newHeader hidden lg:block">
      <div class="max-w-7xl m-auto">
        <div class="flex items-center justify-around">
          <div class="flex gap-x-6 xl:gap-x-10 mt-4">
            <div
              v-for="el in programData"
              :key="el.id"
              class="font-medium cursor-pointer pb-4 text-sm xl:text-md nav-item"
              @click="
                navBarActive(el.name);
                selectedId = el.id;
              "
              :tabId="el.name"
            >
              {{ el.name }}
            </div>
          </div>
          <a href="https://apply.shardaonline.ac.in/" target="_blank">
            <div
              class="mt-4 cursor-pointer bg-[#EC027C] rounded-full py-3 lg:w-[8rem] xl:w-[10rem] text-center text-white font-medium mb-4"
            >
              Apply Now
            </div>
          </a>
        </div>
      </div>
    </div>
    <onlineElectiveVue id="Electives" :elective="electiveData" />
    <div class="flex flex-col" id="Why Us?">
      <div>
        <WhySharda />
      </div>
      <div
        class="flex justify-center bg-gradient-to-b from-white to-[#EFEFEF] py-4 pb-[10px] mt-0"
      >
        <div class="flex flex-col gap-4">
          <h2
            class="text-[#18479E] text-[18px] lg:text-[30px] text-center font-medium"
          >
            So, why wait?
            <span class="text-[#EC027C]"
              >An unmatched online <br />learning experience</span
            >
            awaits you!
          </h2>
          <div class="flex justify-center">
            <button
              class="lg:text-[16px] text-[#EC027C] border w-[200px] h-[50px] rounded-full border-[#EC027C] font-bold"
              @click="openModal"
            >
              Enroll Now
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- enquire modal -->
    <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content rounded-md relative"
      >
        <button class="close rounded-full" @click="closeModal">x</button>
        <enquireForm />
      </div>
    </div>
    <!-- enquire modal -->
    <PlacementSupport id="Placement Support" />
    <div id="Degree">
      <certificationVue />
    </div>
    <opportunityVue id="Opportunities" v-if="program" :program="program" />
    <keyTakwayView id="Takeaways" />
    <aluminaiWorkView id="Companies" />
    <enquireView id="FAQs" v-if="program.id" :program="program.id" />
    <footerView />
    <stickyView />
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import bannerView from "../components/programm/banner.vue";
import onlineElectiveVue from "../components/programm/onlineElective.vue";
import WhySharda from "@/components/home/whySharda.vue";
import PlacementSupport from "@/components/home/PlacementSupport.vue";
import certificationVue from "@/components/home/certification.vue";
import opportunityVue from "../components/programm/opportunity.vue";
import keyTakwayView from "@/components/programm/keyTakeway.vue";
import aluminaiWorkView from "@/components/home/aluminaiwork.vue";
import enquireView from "@/components/programm/enquireView.vue";
import enquireForm from "@/components/enquireNowForms/enquire.vue";
import stickyView from "@/components/common/sticky.vue";
import axios from "axios";

export default {
  name: "programView",
  components: {
    headerView,
    footerView,
    bannerView,
    onlineElectiveVue,
    WhySharda,
    PlacementSupport,
    certificationVue,
    opportunityVue,
    keyTakwayView,
    aluminaiWorkView,
    enquireView,
    stickyView,
    enquireForm,
  },
  props: ["slug"],
  data() {
    return {
      showNewHeaderMobile: false,
      showHeader: false,
      navClick: false,
      programData: [
        { id: 1, name: "Electives" },
        { id: 2, name: "Why Us?" },
        { id: 3, name: "Placement Support" },
        { id: 4, name: "Degree" },
        { id: 5, name: "Opportunities" },
        { id: 7, name: "Takeaways" },
        { id: 8, name: "Companies" },
        { id: 9, name: "FAQs" },
      ],
      selectedId: null,
      program: [],
      electiveData: [],
      isModalOpen: false,
    };
  },
  created() {
    this.programList();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleDesScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.handleDesScroll);
  },
  methods: {
    async programList() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/program-list/?slug=${this.slug}`
        )
        .then((resp) => {
          
          if (resp.data.status === 200) {
            this.program = resp.data.data[0];
            this.electiveList(this.program.short_slug);
        } else {
          this.$router.push({
            name: "PageNotFound",
            params: { pathMatch: "page-not-found" },
          });
        }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async electiveList(data) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/elective-list/?short_slug=${data}`
        )
        .then((resp) => {
          this.electiveData = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleScroll() {
      const banner = this.$refs.banner?.$el;
      if (banner) {
        const bannerBottom = banner.getBoundingClientRect().bottom;
        this.showNewHeaderMobile = bannerBottom <= 0;
      }
      this.updateActiveNavMobile();
    },

    handleDesScroll() {
      const banner = this.$refs.banner?.$el;
      if (banner) {
        const bannerBottom = banner.getBoundingClientRect().bottom;
        this.showHeader = bannerBottom <= 0;
      }
      this.updateActiveNavDesktop();
    },

    navBarActive(tabId) {
      this.navClick = true;
      const targetElement = document.getElementById(tabId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 80,
          behavior: "smooth",
        });

        setTimeout(() => {
          this.navClick = false;
        }, 1000);
      }
    },

    updateActiveNavMobile() {
      const scrollPos = window.scrollY + 80;
      this.programData.forEach((el) => {
        const refElement = document.getElementById(el.name);
        const navItem = document.querySelector(
          `.lg\\:hidden [tabId="${el.name}"]`
        );
        if (refElement && navItem) {
          if (
            refElement.offsetTop <= scrollPos &&
            refElement.offsetTop + refElement.offsetHeight > scrollPos
          ) {
            navItem.classList.add("scrolled");
          } else {
            navItem.classList.remove("scrolled");
          }
        }
      });
    },

    updateActiveNavDesktop() {
      const scrollPos = window.scrollY + 80;
      this.programData.forEach((el) => {
        const refElement = document.getElementById(el.name);

        const navItem = document.querySelector(
          `.lg\\:block [tabId="${el.name}"]`
        );

        if (refElement && navItem) {
          if (
            refElement.offsetTop <= scrollPos &&
            refElement.offsetTop + refElement.offsetHeight > scrollPos
          ) {
            navItem.classList.add("scrolled");
          } else {
            navItem.classList.remove("scrolled");
          }
        }
      });
    },

    openModal() {
      this.isModalOpen = true;

    },

    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style>
.Emodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.Emodal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border: 1px solid #888;
  max-width: 80%;
  width: 500px; 
  margin: 0 20px; 
  box-sizing: border-box;

}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: blue;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: 1.4rem;
  height: 1.4rem;
  text-align: center;
  border-radius: 50%;
  line-height: 1.4rem;
}

@media (max-width: 768px) {
  .Emodal-content {
    width: 80%; 
  }
}

@media (max-width: 480px) {
  .Emodal-content {
    width: 95%; 
  }

  .close {
    font-size: 12px; 
    width: 1.2rem;
    height: 1.2rem;
    top: 5px;
    right: 5px;
  }
}


.newHeaderMobile {
  position: fixed;
  top: 5.5rem;
  left: 0;
  width: 100%;
  height: 4rem;
  background-color: white;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 800; 
}

.newHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7.3rem;
  background-color: white;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure it appears above newHeaderMobile */
  padding-top: 1px;
}

.nav-item.scrolled {
  border-bottom: 2px solid #ec027c; /* Change to your desired border color */
  color: #ec027c;
}
</style>
