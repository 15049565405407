<template>
  <div class="relative">
    <!--mobile desgin-->
    <div
      class="flex justify-between md:justify-between items-center lg:hidden bg-white shadow-sm py-2 fixed top-0 w-full z-50"
    >
      <div class="flex gap-x-4 items-center relative">
        <div
          class="w-[1.8rem] ml-4 md:ml-12 cursor-pointer"
          v-if="!toggleMobileMenu"
          @click="toggleMobileMenu = !toggleMobileMenu"
        >
          <img src="@/assets/home/header/hamburger.png" alt="hamburger" />
        </div>
        <div v-else class="py-3 pl-4">
          <!--step 1-->
          <div v-show="step == 1" class="flex items-center gap-x-4">
            <div @click="toggleMobileMenu = !toggleMobileMenu">
              <img
                src="@/assets/home/header/cross-black.png"
                alt="cross-black"
              />
            </div>
            <div class="text-xl" v-show="toggleMobileMenu">Menu</div>
          </div>
          <!--step 1-->
          <!--step 2-->
          <div v-show="step == 2">
            <div class="flex items-center gap-x-4" @click="reduceStep">
              <div>
                <img
                  src="@/assets/home/header/left-direction.png"
                  alt="left-direction"
                />
              </div>
              <div class="text-lg">Go Back</div>
            </div>
          </div>
          <!--step 2-->
        </div>

        <div class="w-[9rem]" v-show="!toggleMobileMenu">
          <a href="/"
            ><img src="@/assets/home/header/logo.webp" alt="logo"
          /></a>
        </div>
      </div>
      <!-- <div
        class="mr-4 md:mr-12 w-[1.8rem] cursor-pointer"
        v-show="!toggleMobileMenu"
      >
        <img src="@/assets/home/header/search.png" alt="search" />
      </div> -->
      <!-- search -->
      <div>
        <div class="">
          <div
            v-if="ShowIcon"
            @click="toggleSearch"
            class="mr-4 cursor-pointer"
          >
            <img
              v-if="showSearchIcon"
              src="@/assets/home/header/search.png"
              alt="search"
              class="w-[21px] h-[24px] object-contain"
            />
            <img
              v-else
              src="@/assets/home/header/cross-black.png"
              alt="close"
              class="w-[21px] h-[24px] object-contain"
            />
          </div>

          <div
            v-if="showSearch"
            class="absolute py-3 z-[1000px] right-0 left-0 top-[94px] flex flex-col gap-2 items-center bg-white"
          >
            <div
              class="border-b-2 shadow border-[#18479e] flex items-center pb-2 gap-2 w-full"
            >
              <img
                src="@/assets/home/header/search.png"
                alt="searchBar"
                class="w-[30px] h-[20px] object-contain"
              />
              <input
                class="w-[300px] outline-none placeholder-[#18479e] py-1"
                type="text"
                placeholder="What are you looking for?"
                v-model="searchitem"
                @input="fetchResults"
                @keyup.enter="goToSearchPage"
                id="search_box"
                autofocus
              />
              <span
                class="clear cursor-pointer"
                :class="searchClose == false ? 'hidden' : ''"
                @click="searchEmpty"
              ></span>
            </div>
            <div
              class="w-full flex flex-col gap-1 bg-white px-4 h-[180px] overflow-y-auto"
            >
              <h2 class="text-[#EC027C] text-xl">Trendings Programs</h2>

              <div class="flex flex-col gap-1 text-[15px]">
                <a href="/online-mca/computer-science-and-it">
                  <div class="flex items-center gap-2">
                    <img
                      class="w-3 h-3"
                      src="@/assets/home/header/arrow-right.svg"
                      alt="rightDirectionBlack"
                    />
                    <p>
                      Online MCA in Computer Science and Information Technology
                    </p>
                  </div>
                </a>
                <a href="/online-mba/healthcare-and-hospital-administration">
                  <div class="flex items-center gap-2">
                    <img
                      class="w-3 h-3"
                      src="@/assets/home/header/arrow-right.svg"
                      alt="rightDirectionBlack"
                    />
                    <p>Online MBA in Healthcare and Hospital Administration</p>
                  </div>
                </a>
                <a href="/online-mca/data-science">
                  <div class="flex items-center gap-2">
                    <img
                      class="w-3 h-3"
                      src="@/assets/home/header/arrow-right.svg"
                      alt="rightDirectionBlack"
                    />
                    <p>Online MCA in Data Science</p>
                  </div>
                </a>
                <a href="/online-mba/finance">
                  <div class="flex items-center gap-2">
                    <img
                      class="w-3 h-3"
                      src="@/assets/home/header/arrow-right.svg"
                      alt="rightDirectionBlack"
                    />
                    <p>Online MBA in Finance</p>
                  </div>
                </a>
                <!-- <a href="/online-mba/human-resource-management">
                  <div class="flex items-center gap-2">
                    <img class="w-3 h-3" src="@/assets/home/header/arrow-right.svg" alt="rightDirectionBlack" />
                    <p>Online MBA in Human Resource Management</p>
                  </div>
                </a> -->
              </div>
            </div>

            <ul
              v-if="showSuggestions"
              class="search-suggestions absolute bg-white shadow-lg top-[60px] w-full z-50"
            >
              <li
                v-for="result in searchResults.slice(0, 10)"
                :key="result.id"
                class="flex gap-1"
              >
                <img
                  class="object-contain"
                  src="@/assets/home/header/arrow-right.svg"
                  alt="rightDirectionBlack"
                />
                <a
                  :href="`/${result.short_slug}/${result.slug}`"
                  v-if="result"
                  class="flex gap-3 items-center"
                >
                  <p
                    class="text-black text-[12px] text-start text-wrap font-bold"
                  >
                    {{ result.program.name }} in {{ result.name }}
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- search -->
    </div>
    <!--mobile desgin-->
    <!--desktop desgin-->
    <div class="bg-white shadow fixed top-0 w-full z-50">
      <div class="scroll-text hidden lg:block pt-1">
        <a class="text-[#0CB1EF] pl-4">
          SHARDA ONLINE ADMISSION NOTICE 2024-25 (JANUARY 2025 Batch) &nbsp;<a
            href="/Sharda-Online-Admission-Notice-2024-25_January-2025-batch.pdf"
            target="blank"
            ><span class="text-[#18479e] underline">Link</span></a
          >
          &nbsp;&nbsp;&nbsp;
        </a>
        <a
          href="https://api.shardaonline.ac.in/media/UGC_20240319152222.pdf"
          target="blank"
          class="text-[#0CB1EF]"
        >
          UGC Important Notification - Precautions to be taken by the students
          before enrolling in programs offered under Open &amp; Distance
          Learning (ODL) and/or Online Learning mode
        </a>
        <a
          href="/pdf_doc/UGC_20241104165932_1.pdf"
          target="blank"
          class="text-[#0CB1EF] pl-4"
        >
          Mandatory compliance to create DEB ID for enrolling learners in Open
          and Distance Learning (ODL)/Online programmes for the academic year
          2024-25, academic session beginning October, 2024 (revised from July-
          August, 2024)
        </a>
      </div>
      <div class="max-w-7xl m-auto">
        <div class="justify-between hidden lg:flex">
          <div class="flex p-2 items-center gap-x-4 xl:gap-x-10">
            <a href="/">
              <div class="w-[10rem]">
                <img src="@/assets/home/header/logo.webp" alt="logo" />
              </div>
            </a>
            <div
              class="flex items-center justify-center gap-x-4 w-[15rem] border-[#EC027C] border-2 rounded-full cursor-pointer py-4"
              @click="exploreProgrammToggle = !exploreProgrammToggle"
            >
              <div
                class="font-bold"
                :class="exploreProgrammToggle ? 'text-[#EC027C]' : 'text-black'"
              >
                Explore Programs
              </div>
              <div v-if="!exploreProgrammToggle">
                <img src="@/assets/home/header/downarrow.png" alt="downArrow" />
              </div>
              <div v-else>
                <img
                  src="@/assets/home/header/uparrowPink.png"
                  alt="uparrowPink"
                  class="w-[11px]"
                />
              </div>
            </div>
            <a href="/about-us">
              <div class="font-semibold cursor-pointer text-[14px]">
                About Us
              </div>
            </a>
            <a href="/blogs">
              <div class="font-semibold cursor-pointer text-[14px]">Blogs</div>
            </a>
            <!-- <a href="https://learn.shardaonline.ac.in/" target="_blank"
              ><div class="font-semibold cursor-pointer text-[14px]">
                LMS
              </div></a
            > -->
            <a href="/contact-us">
              <div class="font-semibold cursor-pointer text-[14px]">
                Contact us
              </div>
            </a>
          </div>
          <div class="flex p-4 items-center gap-x-4 xl:gap-x-6">
            <!-- <div class="w-[1.5rem] cursor-pointer">
              <img src="@/assets/home/header/search.png" alt="search" />
            </div> -->
            <!-- search -->
            <div>
              <div class="relative">
                <div
                  class="w-[1.5rem] cursor-pointer"
                  v-if="ShowIcon"
                  @click="toggleSearch"
                >
                  <img
                    v-if="showSearchIcon"
                    src="@/assets/home/header/search.png"
                    alt="search"
                  />
                  <img
                    v-else
                    src="@/assets/home/header/cross-black.png"
                    alt="close"
                    class="w-[21px] h-[24px] object-contain"
                  />
                </div>

                <!-- <div
                  v-if="showSearch"
                  id="box"
                  class="absolute p-3 z-[1000px] left-[-750px] xl:left-[-900px] top-[59px] flex flex-col gap-2 items-center bg-white"
                >
                  <div
                    class="border-b-2 border-[#18479e] flex items-center pb-2 gap-2 "
                  >
                    <img
                      src="@/assets/home/header/search.png"
                      alt="searchBar"
                      class="w-[30px] h-[20px] object-contain"
                    />
                    <input
                      class="w-[900px] outline-none bg-transparent placeholder-[#18479e]"
                      type="text"
                      placeholder="What are you looking for?"
                      v-model="searchitem"
                      @input="fetchResults"
                      @keyup.enter="goToSearchPage"
                      id="search_box"
                      autofocus
                    />
                    <span
                      class="clear cursor-pointer"
                      :class="searchClose == false ? 'hidden' : ''"
                      @click="searchEmpty"
                    ></span>
                  </div>
                  <div
                    class="w-full flex flex-col gap-1 bg-white px-4 h-[180px] overflow-y-auto"
                  >
                    <h1 class="text-[#EC027C] text-xl">Trendings Programs</h1>

                    <div class="flex flex-col gap-1">
                      <a href="/online-mca/cloud-computing">
                        <div class="flex items-center gap-2">
                          <img
                            class="w-3 h-3"
                            src="@/assets/home/header/arrow-right.svg"
                            alt="rightDirectionBlack"
                          />
                          <p>Online MCA in Cloud Computing</p>
                        </div>
                      </a>
                      <a href="/online-mba/project-management">
                        <div class="flex items-center gap-2">
                          <img
                            class="w-3 h-3"
                            src="@/assets/home/header/arrow-right.svg"
                            alt="rightDirectionBlack"
                          />
                          <p>Online MBA in Project Management</p>
                        </div>
                      </a>
                      <a href="/online-mcom/accounting-and-finance">
                        <div class="flex items-center gap-2">
                          <img
                            class="w-3 h-3"
                            src="@/assets/home/header/arrow-right.svg"
                            alt="rightDirectionBlack"
                          />
                          <p>Online M.COM in Accounting and Finance</p>
                        </div>
                      </a>
                      <a href="/online-mba/international-business">
                        <div class="flex items-center gap-2">
                          <img
                            class="w-3 h-3"
                            src="@/assets/home/header/arrow-right.svg"
                            alt="rightDirectionBlack"
                          />
                          <p>Online MBA in International Business</p>
                        </div>
                      </a>
                      <a href="/online-mba/data-science-and-analytics">
                        <div class="flex items-center gap-2">
                          <img
                            class="w-3 h-3"
                            src="@/assets/home/header/arrow-right.svg"
                            alt="rightDirectionBlack"
                          />
                          <p>Online MBA in Data Science and Analytics</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <ul
                    v-if="showSuggestions"
                    class="search-suggestions absolute bg-white  top-[50px] w-[920px] right-[10px] xl:!-right-[-10px] z-50"
                  >
                    <li
                      v-for="result in searchResults.slice(0, 10)"
                      :key="result.id"
                    >
                      <a
                        :href="`/${result.short_slug}/${result.slug}`"
                        v-if="result"
                        class="flex gap-3 items-center"
                      >
                        <img
                          src="@/assets/home/header/arrow-right.svg"
                          alt="rightDirectionBlack"
                        />
                        <p
                          class="text-black text-[12px] text-start text-wrap font-bold"
                        >
                          {{ result.program.name }} in {{ result.name }}
                        </p>
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
            <!-- search -->
            <div
              @click="openModal"
              class="bg-[#EC027C] rounded-full p-3 cursor-pointer w-[10rem] text-center text-white font-bold"
            >
              Enquire Now
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showSearch"
        id="box"
        class="absolute p-3 z-[1000px] h-[100vh] w-[100vw] flex flex-col gap-2 items-center bg-white hidden lg:block"
      >
        <div class="flex flex-col items-center">
          <div
            class="xxl:w-[1220px] border-b-2 border-[#18479e] flex items-center pb-2 gap-2"
          >
            <img
              src="@/assets/home/header/search.png"
              alt="searchBar"
              class="w-[30px] h-[20px] object-contain"
            />
            <input
              class="w-[900px] outline-none bg-transparent placeholder-[#18479e]"
              type="text"
              placeholder="What are you looking for?"
              v-model="searchitem"
              @input="fetchResults"
              @keyup.enter="goToSearchPage"
              id="search_box"
              autofocus
            />
            <span
              class="clear cursor-pointer"
              :class="searchClose == false ? 'hidden' : ''"
              @click="searchEmpty"
            ></span>
          </div>
          <div
            class="xxl:w-[1220px] xl:w-[960px] flex flex-col gap-1 bg-white px-4 overflow-y-auto"
          >
            <h2 class="text-[#EC027C] text-xl">Trendings Programs</h2>

            <div class="flex flex-col gap-1">
              <!-- <a href="/online-mca/cloud-computing">
                <div class="flex items-center gap-2">
                  <img class="w-3 h-3" src="@/assets/home/header/arrow-right.svg" alt="rightDirectionBlack" />
                  <p>Online MCA in Cloud Computing</p>
                </div>
              </a> -->
              <a href="/online-mca/computer-science-and-it">
                <div class="flex items-center gap-2">
                  <img
                    class="w-3 h-3"
                    src="@/assets/home/header/arrow-right.svg"
                    alt="rightDirectionBlack"
                  />
                  <p>
                    Online MCA in Computer Science and Information Technology
                  </p>
                </div>
              </a>
              <a href="/online-mba/healthcare-and-hospital-administration">
                <div class="flex items-center gap-2">
                  <img
                    class="w-3 h-3"
                    src="@/assets/home/header/arrow-right.svg"
                    alt="rightDirectionBlack"
                  />
                  <p>Online MBA in Healthcare and Hospital Administration</p>
                </div>
              </a>
              <a href="/online-mca/data-science">
                <div class="flex items-center gap-2">
                  <img
                    class="w-3 h-3"
                    src="@/assets/home/header/arrow-right.svg"
                    alt="rightDirectionBlack"
                  />
                  <p>Online MCA in Data Science</p>
                </div>
              </a>
              <a href="/online-mba/finance">
                <div class="flex items-center gap-2">
                  <img
                    class="w-3 h-3"
                    src="@/assets/home/header/arrow-right.svg"
                    alt="rightDirectionBlack"
                  />
                  <p>Online MBA in Finance</p>
                </div>
              </a>
            </div>
          </div>
          <ul
            v-if="showSuggestions"
            class="search-suggestions absolute bg-white top-[50px] w-[920px] z-50"
          >
            <li v-for="result in searchResults.slice(0, 10)" :key="result.id">
              <a
                :href="`/${result.short_slug}/${result.slug}`"
                v-if="result"
                class="flex gap-3 items-center"
              >
                <img
                  src="@/assets/home/header/arrow-right.svg"
                  alt="rightDirectionBlack"
                />
                <p
                  class="text-black text-[12px] text-start text-wrap font-bold"
                >
                  {{ result.program.name }} in {{ result.name }}
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--desktop desgin-->
    <!--explore desktop programm hover-->
    <div
      class="fixed top-[9%] lg:right-0 2xl:top-[116px] xl:top-[116px] xl:right-[0%] 2xl:right-[0%] xxl:right-[0%] bg-white shadow !z-50 w-full max-w-full mx-auto hidden lg:block"
      v-show="exploreProgrammToggle"
    >
      <div class="flex h-[100vh]">
        <div class="min-w-[35%] bg-[#FCECF4]">
          <div class="flex flex-col pl-8">
            <div class="mt-8 font-bold text-[#EC027C] text-xl mr-auto">
              Master's Degree
            </div>
            <div v-for="(el, index) in programs" :key="el.id">
              <div
                class="mr-auto cursor-pointer flex items-center gap-x-2"
                @click="electiveList(el.slug), (selectedProgram = el.id)"
                v-if="index < 2"
              >
                <div v-if="selectedProgram != el.id">
                  <img
                    src="@/assets/home/header/arrow-right.svg"
                    alt="rightDirectionBlack"
                  />
                </div>
                <div v-else>
                  <img
                    src="@/assets/home/header/rightarrow.png"
                    alt="rightDirectionBlack"
                    class="w-[.6rem]"
                  />
                </div>
                <div
                  :class="
                    el.id == selectedProgram ? 'font-bold' : 'text-[#4D4D4D]'
                  "
                >
                  {{ el.program }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col pl-8">
            <div class="mt-8 font-bold text-[#EC027C] text-xl mr-auto">
              Bachelor’s Degree
            </div>
            <div v-for="(el, index) in programs" :key="el.id">
              <div
                class="mr-auto font-light cursor-pointer flex items-center gap-x-2"
                @click="electiveList(el.slug), (selectedProgram = el.id)"
                v-if="index > 1"
              >
                <div v-if="selectedProgram != el.id">
                  <img
                    src="@/assets/home/header/arrow-right.svg"
                    alt="rightDirectionBlack"
                  />
                </div>
                <div v-else>
                  <img
                    src="@/assets/home/header/rightarrow.png"
                    alt="rightDirectionBlack"
                    class="w-[.6rem]"
                  />
                </div>
                <div
                  :class="
                    el.id == selectedProgram ? 'font-bold' : 'text-[#4D4D4D]'
                  "
                >
                  {{ el.program }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="min-w-[65%] mt-6">
          <div class="flex justify-around items-center">
            <div class="place-self-start w-[50%]">
              <div
                v-if="electiveData"
                class="text-[#18479E] font-medium lg:text-md xl:text-lg"
              >
                <a :href="'/' + exploreProgramHeadline.slug">
                  {{ exploreProgramHeadline.sub_name }}
                </a>
              </div>
              <div v-for="el in electiveData" :key="el.id">
                <a :href="'/' + el.program.short_slug + '/' + el.slug">
                  <div class="pt-1 flex gap-3 text-[#4D4D4D]">
                    <img
                      src="@/assets/home/header/arrow-right.svg"
                      alt="arrow-right"
                    />{{ el.name }}
                  </div>
                </a>
              </div>
            </div>
            <div class="W-[50%]">
              <div>
                <img
                  src="@/assets/home/header/menu_image.webp"
                  alt="banner_mobile"
                  class="w-[20rem]"
                />
              </div>
              <div
                class="mt-4 text-[#18479E] text-6xl opacity-20 absolute w-max right-[6%] bottom-[15%] font-bold"
              >
                Explore Programs
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--explore programm hover-->
    <!--enquire modal-->
    <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content rounded-md relative w-full md:w-[35%] 2xl:w-[30%]"
      >
        <button class="close rounded-full" @click="closeModal">x</button>
        <enquireView />
      </div>
    </div>
    <!--enquire modal-->
    <!--menu mobile-->
    <div
      class="bg-white z-10 fixed top-[8%] md:py-0 h-screen w-full lg:hidden"
      v-if="toggleMobileMenu"
    >
      <!--step1-->
      <div v-show="step == 1" class="px-8 py-8">
        <div class="flex justify-between items-center" @click="updateStep">
          <div class="text-xl text-[#EC027C] font-bold">Programs</div>
          <div>
            <img src="@/assets/home/header/rightarrow.png" alt="rightArrow" />
          </div>
        </div>
        <a href="/about-us">
          <div class="py-5 text-[#EC027C] font-bold text-xl">About</div>
        </a>
        <a href="/blogs">
          <div class="text-[#EC027C] font-bold text-xl mb-4">Blogs</div>
        </a>
        <!-- <a href="https://learn.shardaonline.ac.in/" target="_blank"
          ><div class="text-[#EC027C] font-bold text-xl py-6">LMS</div></a
        > -->
        <a href="/contact-us">
          <div class="text-[#EC027C] font-bold text-xl">Contact us</div>
        </a>
        <div class="mt-[10rem]">
          <div><img src="@/assets/home/header/logo.webp" alt="logo" /></div>
          <div class="mt-6 text-xl font-bold text-[#18479E]">
            #RahoHarKadamAage
          </div>
        </div>
      </div>
      <!--step1-->
      <!--step2-->
      <div v-show="step == 2" class="py-6 md:py-0">
        <div>
          <div class="flex md:justify-around text-xl">
            <div
              class="px-2 py-3 w-full text-center"
              @click="
                tab = 'masters';
                fetchElectiveProgram('masters');
              "
              :class="
                tab == 'masters'
                  ? 'bg-[#FCECF4] text-[#EC027C] font-bold'
                  : 'bg-[#0000000A] text-[#18479E] font-medium '
              "
            >
              Master’s Degree
            </div>
            <div
              class="px-2 py-3 w-full text-center"
              @click="
                tab = 'bachelors';
                fetchElectiveProgram('bachelors');
              "
              :class="
                tab == 'bachelors'
                  ? 'bg-[#FCECF4] text-[#EC027C] font-bold'
                  : 'bg-[#0000000A] text-[#18479E] font-medium'
              "
            >
              Bachelor’s Degree
            </div>
          </div>
        </div>
        <div v-for="el in programData" :key="el.id">
          <div
            class="flex justify-between md:justify-around pt-6"
            @click="
              newElectiveList(el.short_slug);
              programId = el.id;
            "
          >
            <div class="text-start pl-3 font-bold">{{ el.name }}</div>
            <div class="pr-3" v-if="el.id != programId">
              <img
                src="@/assets/home/header/downarrowPink.png"
                alt="downarrow"
              />
            </div>
            <div class="pr-3" v-else>
              <img src="@/assets/home/header/uparrowPink.png" alt="downarrow" />
            </div>
          </div>

          <div v-if="el.id == programId">
            <a :href="el.slug">
              <div class="text-md px-3 text-[#18479E]">
                {{ el.sub_name }}
              </div>
            </a>
            <div v-for="el in mobElectiveData" :key="el.id" class="ml-4">
              <a :href="'/' + el.program.short_slug + '/' + el.slug">
                <div class="flex items-center gap-x-4">
                  <span
                    ><img
                      src="@/assets/home/header/arrow-right.svg"
                      alt="rightDirection"
                  /></span>
                  {{ el.name }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--step2-->
    </div>
    <!--menu mobile-->
  </div>
</template>

<script>
import axios from "axios";
import { useHead } from "@unhead/vue";
import enquireView from "../enquireNowForms/enquire.vue";
import { useRoute } from "vue-router";
export default {
  name: "headerView",
  components: {
    enquireView,
  },
  data() {
    return {
      electiveData: [],
      exploreProgrammToggle: false,
      searchClose: false,
      showSearchIcon: true,
      isModalOpen: false,
      toggleMobileMenu: false,
      step: 1,
      tab: "masters",
      programData: [],
      mobElectiveData: [],
      programId: null,
      programHighlight: false,
      programs: [
        {
          id: 1,
          program: "Master of Business Administration (MBA)",
          slug: "online-mba",
        },
        {
          id: 2,
          program: "Master of Computer Application (MCA)",
          slug: "online-mca",
        },
        /* {
           id: 3,
           program: "Master of Commerce (M.Com)",
           slug: "online-mcom",
         },
         {
           id: 4,
           program: "Bachelor of Business Administration (BBA)",
           slug: "online-bba",
         },
         {
           id: 5,
           program: "Bachelor of Computer Application (BCA)",
           slug: "online-bca",
         },*/
        {
          id: 6,
          program: "Bachelor of Arts (BA)",
          slug: "online-ba",
        },
      ],
      selectedProgram: 1,
      exploreProgramHeadline: {
        sub_name: "",
        slug: "",
      },
      showSearch: false,
      searchitem: "",
      searchResults: [],
      showSuggestions: false,
      clicked: false,
    };
  },

  setup() {
    const route = useRoute();

    const noindexPages = [
      "/refundpolicy",
      "/notifications",
      "/pdf_doc/Declaration-CIQA",
      "/online-bca-program",
      "/masters-business-administration-online-degre",
      "/online-bba/international-finance-bba",
      "/page-not-found",
      "/online-bca-degree/computer-science-and-it",
      "/online-mca-degree/computer-science-and-it",
      "/online-mca-degree/data-science",
      "/masters-business-administration-Online-degree",
      "/programs",
      "/online-mba/business-analytics",
    ];

    if (noindexPages.includes(route.path)) {
      useHead({
        meta: [
          {
            name: "robots",
            content: "noindex",
          },
        ],
      });
    }
  },

  computed: {
    ShowIcon() {
      return this.$route.path !== "/search";
    },
  },
  created() {
    this.fetchElectiveProgram();
    this.electiveList();
    this.validating();
  },
  async mounted() {
    let seoTag = await this.bindMetaTags();
    useHead({
      title: seoTag.title,
      meta: seoTag.meta,
      link: seoTag.link,
    });
  },
  methods: {
    searchEmpty() {
      this.searchitem = "";
      this.searchClose = false;
      this.showSuggestions = false;
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      this.showSearchIcon = !this.showSearchIcon;
    },
    validating() {
      if (this.$route.path == "/") {
        this.validatingRoute = false;
      }
    },
    async bindMetaTags() {
      var metaTagDic = {};
      metaTagDic["meta"] = [];
      metaTagDic["link"] = [];
      metaTagDic["title"] = "";
      metaTagDic["script"] = [];
      await axios
        .get(`${process.env.VUE_APP_API}/seo-list/?url=${this.$route.path}`)
        .then((resp) => {
          console.log(resp, "here there is god");
          if (
            resp.data.status == 200 &&
            resp.data.data &&
            resp.data.data.length > 0
          ) {
            var result = resp.data.data[0];

            metaTagDic["title"] = result.title;

            metaTagDic["meta"] = [
              { name: "description", content: result.description },
              { name: "keywords", content: result.keyword.join(",") },
              { name: "og_title", content: result.og_title },
              { name: "og_img", content: result.og_img },
              { name: "og_description", content: result.og_description },
              { name: "twitter:domain", content: "shardaonline.ac.in" },
              { name: "twitter:url", content: result.og_site_url },
              { name: "twitter_title", content: result.twitter_title },
              { name: "twitter:image:alt", content: result.og_title },
              { name: "twitter_img", content: result.twitter_img },
              { name: "twitter_card", content: result.twitter_card },
            ];
            metaTagDic["link"] = [
              {
                rel: "canonical",
                href: "https://shardaonline.ac.in" + this.$route.fullPath,
              },
            ];
            console.log(result.schema_code, "test");
            if (result.schema_code) {
              metaTagDic["script"] = {
                type: "application/ld+json",
                textContent: JSON.stringify(result.schema_code),
              };
            }

            useHead({
              title: metaTagDic["title"],
              meta: metaTagDic["meta"],
              link: metaTagDic["link"],
              script: metaTagDic["script"],
            });
          }
        });

      return metaTagDic;
    },
    updateStep() {
      this.step = this.step + 1;
    },
    reduceStep() {
      this.step = this.step - 1;
    },
    async electiveList(data) {
      if (!data) {
        data = "online-mba";
      }
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/elective-list/?program__short_slug=${data}`
        )
        .then((resp) => {
          this.electiveData = resp.data.data;
          this.exploreProgramHeadline = this.electiveData[0].program;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async newElectiveList(data) {
      if (!data) {
        data = this.programData[0].short_slug;
      }
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/elective-list/?program__short_slug=${data}`
        )
        .then((resp) => {
          this.mobElectiveData = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async fetchElectiveProgram(degree) {
      if (!degree) {
        degree = "masters";
      }
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/program-list/?category__slug=${degree}`
        )
        .then((resp) => {
          this.programData = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchResults() {
      if (this.searchitem == "") {
        this.searchClose = false;
        this.showSuggestions = false;
      } else {
        this.searchClose = true;
      }
      if (this.searchitem.length > 0) {
        axios
          .get(
            `${process.env.VUE_APP_API}/lp/landingpage-search/?search=${this.searchitem}`
          )
          .then((response) => {
            console.log("data", response.data);
            this.searchResults = response.data.electives;
            this.showSuggestions = true;
          })

          .catch((error) => {
            console.error("Error fetching search results:", error);
          });
      } else {
        this.searchResults = [];
        this.showSuggestions = false;
      }
    },
    selectSuggestion(suggestion) {
      console.log("Selected suggestion:", suggestion);
      this.searchitem = suggestion.title;
      this.showSuggestions = false;
    },
    async goToSearchPage() {
      if (this.searchitem && this.searchitem.trim() !== "") {
        try {
          const response = await axios.get(
            `${
              process.env.VUE_APP_API
            }/lp/landingpage-search/?search=${encodeURIComponent(
              this.searchitem
            )}`
          );

          const programs = response.data.programs || [];
          const electives = response.data.electives || [];
          const blogs = response.data.blogs || [];

          // Determine the most relevant tab
          let activeTab = "Programs";
          if (electives.length > 0 && programs.length === 0) {
            activeTab = "Electives";
          } else if (
            blogs.length > 0 &&
            electives.length === 0 &&
            programs.length === 0
          ) {
            activeTab = "Blogs";
          } else if (electives.length > 0 && programs.length > 0) {
            // If both electives and programs are present, determine based on search term relevance
            const electiveMatch = electives.some((elective) =>
              this.searchitem
                .toLowerCase()
                .includes(elective.name.toLowerCase())
            );
            const programMatch = programs.some((program) =>
              this.searchitem.toLowerCase().includes(program.name.toLowerCase())
            );
            activeTab =
              electiveMatch && !programMatch ? "Electives" : "Programs";
          }

          // Store the active tab data in localStorage
          localStorage.setItem("searchitem", this.searchitem);
          localStorage.setItem("activeTab", activeTab);

          // Perform the navigation to search page
          window.isProgrammaticNavigation = true;
          this.$router.push(`/search?tab=${activeTab}`);
        } catch (error) {
          console.error("Error determining search results:", error);
          alert("Error processing the search. Please try again.");
        }
      } else {
        alert("Please enter a search term");
      }
    },
  },
};
</script>

<style scoped>
.clear:after {
  content: "X";
  position: absolute;
  color: #373737;
  font-size: 20px;
  top: 12px;
  right: 20px;
}

.search-suggestions {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  color: black;
}

.search-suggestions li {
  padding: 10px;
  cursor: pointer;
  border: none;
}

.search-suggestions li:hover {
  background-color: #f0f0f0;
}

.Emodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2001;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Emodal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border: 1px solid #888;
  max-width: 90%;
  width: 500px;
  margin: 0 20px;
  box-sizing: border-box;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: blue;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: 1.4rem;
  height: 1.4rem;
  text-align: center;
  border-radius: 50%;
  line-height: 1.4rem;
}

@media (max-width: 768px) {
  .Emodal-content {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .Emodal-content {
    width: 95%;
  }

  .close {
    font-size: 12px;
    width: 1.2rem;
    height: 1.2rem;
    top: 5px;
    right: 5px;
  }
}

.electives {
  display: flex;
  flex-direction: column;
  background: none;
}

.ele {
  border: none;
  font-size: 13px;
  font-weight: 500;
}

.scroll-text {
  color: #001c50;
  font-size: 15px;
  font-weight: bolder;
  white-space: nowrap;
  overflow: inherit;
}

@media (max-width: 480px) {
  .scroll-text {
    color: #001c50;
    font-size: small;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
  }
}

.scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 30s linear infinite;
}

.scroll-text:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
</style>
