<template>
    <div class="bg-gray-200 px-5">
      <div class="w-full max-w-7xl mx-auto p-5">
        <h1
          class="text-[24px] lg:text-[30px] text-[#17479e] font-bold text-center uppercase py-1"
        >
          who can apply
        </h1>
        <div class="flex gap-[30px] items-center md:h-[280px]">
          <div class="hidden md:block">
            <img
              src="@/assets/lpProgramm/5.webp"
              alt=""
              class="h-[180px] object-contain w-[200px]"
            />
          </div>
          <div class="flex flex-col gap-3 w-full  ">
            <h1
              class="text-[#00a1e4] text-xl font-semibold text-left hidden "
            >
              Sharda University Admission - Eligibility
            </h1>
            <!-- <p
              class="text-[12px] text-left hidden md:block"
              v-html="program.eligibility"
            ></p> -->
            <!-- <h1
              class="text-[#00a1e4] text-lg font-bold text-left"
            >
              Undergraduate (Bachelors) Program
            </h1>
            <p class="text-sm text-left">
              Candidates must have passed the (10+2) examination from a State
              Board / CBSE / NIOS / IGCSE / IB / ICSE recognized by the State or
              Central Government.
            </p> -->
            <h1
              class="text-[#00a1e4] text-lg font-bold text-left"
            >
              Postgraduate (Masters) Program
            </h1>
            <p class="text-sm text-left">
              Pass in an Undergraduate (Bachelor) Program of a minimum duration of three (3) years in any stream from a UGC recognized University, with a minimum aggregate of 45% or an equivalent letter/numerical grade.
            </p>
  
            <div
              class="flex flex-col lg:flex-row  items-center gap-6"
            >
              <button
                @click="scrollToTop()"
                class="text-white bg-[#00a1e4] justify-left w-[145px] font-bold py-3 rounded-md hidden md:block"
              >
                ENQUIRE NOW
              </button>
              <!-- <a href="tel:+919999999999" class="w-full">
                <button
                  class="text-white bg-black w-[200px] h-[30px] rounded-md hidden md:block"
                >
                  Talk to a counselor
                </button>
              </a> -->
              <button
                @click="scrollToTop()"
                class="text-white bg-blue-400 w-[65%] py-3 rounded-md block md:hidden"
              >
                APPLY NOW
              </button>
              <!-- <a href="tel:+919999999999" class="w-full">
                <button
                  class="text-white bg-black w-full py-3 rounded-md block md:hidden"
                >
                  <div class="flex gap-3 justify-center w-full">
                    <img
                      src="@/assets/lp-program/Iconmaterial-call.svg"
                      alt=""
                      class="text-white"
                    />
                    Talk to a counselor
                  </div>
                </button>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "WhoApply",
    props: ["program"],
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
    },
  };
  </script>
  
  <style></style>
  